import {
    COLOR_ATTRIBUTE,
    FRAME_SHAPE_ATTRIBUTE,
    FRAME_SIZE_ATTRIBUTE,
    WHEEL_SIZE_ATTRIBUTE,
} from './attributes';
import { UIConfig, UIPage, UISiteConfig, UITheme } from './uiConfig';

const config: UIConfig = {
    header: {
        darkTheme: new Set([
            UIPage.Home,
            UIPage.Category,
            UIPage.Product,
            UIPage.PublicationListing,
            UIPage.Publication,
            UIPage.ProductLine,
            UIPage.DealerDetail,
            UIPage.ProductLineListing,
            UIPage.CampaignListing,
            UIPage.Campaign,
            UIPage.StoreLocator,
            UIPage.Basket,
            UIPage.Checkout,
            UIPage.NotFound,
            UIPage.BikeComparison,
            UIPage.ContactMethodsListingPage,
            UIPage.ContactMethod,
            UIPage.ShippingMethodsListingPage,
            UIPage.ShippingMethod,
            UIPage.PaymentMethodsListingPage,
            UIPage.PaymentMethod,
        ]),
    },
    footer: {
        darkTheme: new Set([
            UIPage.Home,
            UIPage.Basket,
            UIPage.Category,
            UIPage.Product,
            UIPage.ProductLine,
            UIPage.ProductLineListing,
            UIPage.PublicationListing,
            UIPage.Publication,
            UIPage.CampaignListing,
            UIPage.Campaign,
            UIPage.NotFound,
            UIPage.StoreLocator,
            UIPage.BikeComparison,
        ]),
    },
};

export const uiSiteConfig: UISiteConfig = {
    defaultHrefLang: 'en-gb',
    awinEnabled: true,
    urlStructure: {
        productListing: {
            urlSegmentation: {
                enabled: false,
            },
        },
        productDetail: {
            /* Harmonized to match standard approach */
            path: {
                parentProduct: [{ productKey: 'name' }, { productKey: 'sku' }],
            },
            urlSegmentation: {
                enabled: true,
            },
        },
        productLineDetail: {
            urlSegmentation: {
                enabled: true,
            },
        },
        localeSeparator: '-',
    },
    Product: {
        optionsDisplayRules: [
            {
                attributeKeys: [FRAME_SHAPE_ATTRIBUTE],
                priority: 1,
                force: true,
            },
            {
                attributeKeys: [COLOR_ATTRIBUTE],
                priority: 2,
                force: true,
            },
            {
                attributeKeys: [FRAME_SIZE_ATTRIBUTE],
                priority: 3,
                force: true,
            },
            {
                attributeKeys: [WHEEL_SIZE_ATTRIBUTE],
                priority: 4,
                force: true,
            },
        ],
        breadcrumbsDepth: 3,
        showIconsForSingleProduct: [],
        smartfit: {
            visible: false,
            sizeAttribute: FRAME_SIZE_ATTRIBUTE,
        },
        geometry: {
            visible: false,
        },
    },
    fonts: {
        urlFile: ['/fonts/fonts.css'],
    },
    showCookiesAndYtCTA: {
        visible: true, // Enable the cookie consent CTA style
    },
    ProductListing: {
        displayPageTotalCount: true,
        categoryPageSize: 90,
        readMoreButtonWithTitle: true,
        columns: {
            xxl: 3,
        },
        filters: {
            default: [
                'UA-INHOUD',
                'UP-BEVMAT',
                'AACOL',
                'UP-DIACLMP',
                'UP-WIDTHST',
                'UA-PRUITV',
                'UP-REACH',
                'UP-STDROP',
                'DIAMETER',
                'UA-PRLYN',
                'UP-FDCAPA',
                'UP-FDKANTE',
                'UP-FDEBIKE',
                'UP-MATBOTC',
                'UP-BRTYP',
                'UP-BRVING',
            ],
        },
        propertieIncludes:  [
            'UA-INHOUD',
            'UP-BEVMAT',
            'AACOL',
            'UP-DIACLMP',
            'UP-WIDTHST',
            'UA-PRUITV',
            'UP-REACH',
            'UP-STDROP',
            'DIAMETER',
            'UA-PRLYN',
            'UP-FDCAPA',
            'UP-FDKANTE',
            'UP-FDEBIKE',
            'UP-MATBOTC',
            'UP-BRTYP',
            'UP-BRVING',
            'attrMarketingColor',
            'attrFrameShape',
            'attrFrameSize',
            'attrWheelSize'
        ],
    },
    OCampaignListXL: {
        showBackgroundVideo: true,
    },
    OProductFull: {
        hero: {
            visible: false,
            theme: UITheme.Light,
        },
        reviews: {
            visible: false,
            exclude: [],
        },
        yotpoGallery: {
            visible: false,
        },
        images: {
            layout: 'carousel',
            containImages: true,
        },
        config: {
            theme: UITheme.Light,
            preferOnlineSales: false,
            oneyPriceRange: {},
        },
        description: {
            theme: UITheme.Light,
        },
        keyFeatures: {
            visible: true,
            theme: UITheme.Light,
            direction: 'horizontal',
            accordion: false,
        },
        geometry: {
            theme: UITheme.Light,
        },
        specifications: {
            theme: UITheme.Light,
        },
        relatedProducts: {
            theme: UITheme.Light,
        },
        flowboxGallery: {
            visible: false,
        },
        bidex: {
            default: false,
        },
    },
    ProductConfiguratorOptions: {
        justifyOptionLayout: false,
    },
    Campaign: {
        theme: UITheme.Light,
        fullSizeHero: true,
    },
    ProductLine: {
        theme: UITheme.Light,
    },
    ProductLineListing: {
        theme: UITheme.Light,
    },
    SplashPage: {
        displayExtendedVersion: true,
        theme: UITheme.Light,
    },
    Dealer: {
        hasDetailPage: false,
    },
    Home: {
        theme: UITheme.Light,
        yotpoGallery: {
            visible: false,
        },
    },
    Header: {
        centeredLogo: false,
    },
    StoreLocator: {
        filters: {
            default: [],
        },
        filterByType: ['gb', 'ie'],
        theme: UITheme.Light,
    },
    Checkout: {
        shippingLogic: {
            default: undefined,
        },
    },
    Basket: {
        disableQuantitySelection: false,
    },
    OBrandSignature: {
        // TODO: add a shape for raleigh
    },
    FullSizeBannerContent: {
        contentAlignmentConfig: 'centerMid', // This is overridden by the component's own contentAlignment prop from Storyblok
        fluidContainer: true,
        largeHeading: false,
    },
    PublicationListingContent: {
        textAlign: 'center',
    },
    BikeComparison: {
        theme: UITheme.Light,
    },
    ContactMethodsListingPage: {
        theme: UITheme.Light,
    },
    ContactMethodPage: {
        theme: UITheme.Light,
    },
    ShippingMethodsListingPage: {
        theme: UITheme.Light,
    },
    ShippingMethodPage: {
        theme: UITheme.Light,
    },
    PaymentMethodsListingPage: {
        theme: UITheme.Light,
    },
    PaymentMethodPage: {
        theme: UITheme.Light,
    },
    CookieSettings: {
        oneTrustAutoblockEnabled: false,
    },
};

export default config;
