import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'DE', // Default country
    'FI',
    'IT',
    'AT',
    'SE',
    'CH',
    'ES',
    'INT',
    'GB',
    'IE',
    'FR',
];
export const languages: Languages = {
    DE: [
        'de', // Default country/language
        'en',
    ],
    FI: ['fi'],
    IT: ['it'],
    AT: ['de'],
    SE: ['sv'],
    CH: ['de', 'it'],
    ES: ['es'],
    INT: ['en'],
    GB: ['en'],
    IE: ['en'],
    FR: ['fr'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {
    GB: {
        en: 'en-gb', // Override language code with storyblock language code for engilsh-ECOM.
    },
    IE: {
        en: 'en-gb', // Override language code with storyblock language code for engilsh-ECOM.
    },
};
