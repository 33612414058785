import { Countries, Languages, StoryblokAdditionalLanguages } from '../types/Countries';

export const countries: Countries = [
    'GB', // Default country
    'BE',
    'DE',
    'ES',
    'FR',
    'IE',
    'IT',
    'LU',
    'NL',
    'CH',
    'INT',
];

export const languages: Languages = {
    GB: ['en'], // Default country/language
    BE: ['en', 'fr'],
    DE: ['de'],
    ES: ['es'],
    FR: ['fr'],
    IE: ['en'],
    IT: ['it'],
    LU: ['en', 'fr'],
    NL: ['en'],
    CH: ['fr', 'de', 'it'],
    INT: ['en', 'es'],
};

// Override the above country/language codes with specific codes for Storyblok
export const storyblokAdditionalLanguages: StoryblokAdditionalLanguages = {
    GB: {
        en: 'en-gb', // Override language code with storyblock language code for engilsh-ECOM.
    },
};
