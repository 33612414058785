import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export type TitleVariant =
    | 'display1'
    | 'display2'
    | 'display3'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'heading4'
    | 'heading5'
    | 'heading6';

interface Props extends HTMLAttributes<{}> {
    variant?: TitleVariant;
    variantSmall?: TitleVariant; // small screen variant
    className?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardAs?: keyof JSX.IntrinsicElements | React.ComponentType<any>; // renamed as to forwardAs because of passing a prop and a as will not work with styled components (probably a bug in Styled Components)
    color?: string;
    highlightColor?: string;
    removeFormatting?: boolean;
}

const elementMap: Record<TitleVariant, keyof JSX.IntrinsicElements> = {
    display1: 'h1',
    display2: 'h2',
    display3: 'h3',
    heading1: 'h1',
    heading2: 'h2',
    heading3: 'h3',
    heading4: 'h4',
    heading5: 'h5',
    heading6: 'h6',
};

const getCss = (variant: TitleVariant) => {
    switch (variant) {
        case 'display1':
            return display1Css;
        case 'display2':
            return display2Css;
        case 'display3':
            return display3Css;
        case 'heading1':
            return heading1Css;
        case 'heading2':
            return heading2Css;
        case 'heading3':
            return heading3Css;
        case 'heading4':
            return heading4Css;
        case 'heading5':
            return heading5Css;
        case 'heading6':
            return heading6Css;
        default:
            throw new Error('error: no variant chosen');
    }
};

const UHeading: FC<Props> = ({
    variant = 'heading2',
    variantSmall,
    children,
    forwardAs = elementMap[variant],
    className,
    color,
    highlightColor,
    removeFormatting,
    ...rest
}) => (
    <TitleInner
        as={forwardAs}
        $variant={variant}
        $variantSmall={variantSmall}
        $color={color}
        className={className}
        {...rest}
    >
        {typeof children === 'string' ? (
            <FormattedTextHeading highlightColor={highlightColor} asPlainText={removeFormatting}>
                {children}
            </FormattedTextHeading>
        ) : (
            children
        )}
    </TitleInner>
);

const FormattedTextHeading: FC<{
    children: string;
    highlightColor?: string;
    asPlainText?: boolean;
}> = React.memo(({ children, highlightColor, asPlainText }) => {
    const lines = children.split('\n');
    return (
        <>
            {lines.map(
                (line, i) =>
                    line && (
                        <React.Fragment key={i}>
                            <FormattedLine
                                highlightColor={highlightColor}
                                asPlainText={asPlainText}
                            >
                                {line}
                            </FormattedLine>
                            {asPlainText ? ' ' : <br />}
                        </React.Fragment>
                    )
            )}
        </>
    );
});

interface LinePart {
    text: string;
    type: 'plain' | 'alt';
}

/*
 * Title supports these formatting rules:
 * - A line starting with '__' will become indented
 * - A part of a line enclosed within two '*' will get different styling
 */
const FormattedLine: FC<{ children: string; highlightColor?: string; asPlainText?: boolean }> = ({
    children,
    highlightColor,
    asPlainText,
}) => {
    const line = children;
    let i = 0;
    let currentPart = '';
    const parts: LinePart[] = [];

    const readChar = () => {
        currentPart += line[i] ?? '';
        i += 1;
    };

    const endPart = () => {
        if (currentPart.length > 0) {
            const type =
                currentPart.startsWith('*') && currentPart.endsWith('*') && currentPart.length > 2
                    ? 'alt'
                    : 'plain';
            const prevType = parts.length > 0 ? parts[parts.length - 1].type : '';

            if (type === prevType) {
                // can happen when there is a single '*', should not be its own part
                parts[parts.length - 1].text += currentPart;
            } else {
                // should be separate part
                parts.push({
                    text:
                        type === 'alt'
                            ? currentPart.substring(1, currentPart.length - 1)
                            : currentPart,
                    type,
                });
            }
            currentPart = '';
        }
    };

    while (i < line.length) {
        if (line[i] === '*') {
            if (currentPart.startsWith('*')) {
                // c is a closing symbol, so add it to current part
                readChar();
            }
            endPart();
        }
        readChar();
    }
    endPart();

    const isIndented = parts[0]?.text.startsWith('__');
    if (isIndented) {
        // remove '__'
        parts[0].text = parts[0].text.substr(2);
    }

    const LineWrap = isIndented && !asPlainText ? IndentedLine : React.Fragment;

    return (
        <LineWrap>
            {parts.map((part, index) => {
                const PartWrap =
                    part.type === 'alt' && !asPlainText ? HighlightPart : React.Fragment;
                const partProps =
                    part.type === 'alt' && !asPlainText ? { $highlightColor: highlightColor } : {};
                return (
                    <PartWrap key={index} {...partProps}>
                        {part.text}
                    </PartWrap>
                );
            })}
        </LineWrap>
    );
};

export default UHeading;

export const TitleInner = styled.h1<{
    $variant?: TitleVariant;
    $variantSmall?: TitleVariant;
    $color: string;
}>`
    ${({ theme, $color, $variant = 'heading2', $variantSmall }) => css`
        ${getCss($variant)};
        ${$variantSmall &&
        css`
            @media (max-width: ${theme.breakpointExtraSmall}px) {
                ${getCss($variantSmall)}
            }
        `}
        line-height: ${theme.fontLineHeightLarge};
        margin: 0 0 ${theme.sizeGridSpacingRem4};
        font-family: ${theme.fontTitleFamily};
        font-weight: ${theme.fontTitleWeight};
        // word-wrap: break-word;
        color: ${$color ?? theme.colors.textHeadline};
    `};
`;

const display1Css = css`
    ${({ theme }) => css`
        font-family: ${theme.fontTitleFamily} !important;
        font-weight: ${theme.fontTitleWeight};
        text-transform: ${theme.fontTitleTransform};
    `};

    font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleSmall}`};
    margin-bottom: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    line-height: ${({ theme }) => `${theme.fontLineHeightExtraSmall}`}!important;
    letter-spacing: ${({ theme }) => `${theme.fontTitleLetterSpacing}`};

    @media (min-width: ${({ theme }) => `${theme.breakpointLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleMedium}`};
    }
    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleLarge}`};
    }
`;

const display2Css = css`
    ${({ theme }) => css`
        font-family: ${theme.fontTitleFamily} !important;
        font-weight: ${theme.fontTitleWeight};
        text-transform: ${theme.fontTitleTransform};
    `};

    font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleSmall}`};
    margin-bottom: ${({ theme }) => `${theme.sizeGridSpacingRem16}`};
    line-height: ${({ theme }) => `${theme.fontLineHeightExtraSmall}`}!important;
    letter-spacing: ${({ theme }) => `${theme.fontTitleLetterSpacing}`};
`;

const display3Css = css`
    ${({ theme }) => css`
        font-family: ${theme.fontTitleFamily} !important;
        font-weight: ${theme.fontTitleWeight};
        text-transform: ${theme.fontTitleTransform};
    `};
    font-size: ${({ theme }) => `${theme.sizeTextOverlayTitleExtraSmall}`};
    line-height: ${({ theme }) => `${theme.fontLineHeightExtraSmall}`}!important;
    margin-bottom: ${({ theme }) => `${theme.sizeGridSpacingRem20}`};
    letter-spacing: ${({ theme }) => `${theme.fontTitleLetterSpacing}`};
`;

const heading1Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeOneSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontHeadlineOneLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeOneMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontHeadlineOneFamily} !important;
        font-weight: ${theme.fontHeadlineOneWeight};
        text-transform: ${theme.fontHeadlineOneTransform};
    `};
`;

const heading2Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeTwoSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontHeadlineTwoLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeTwoMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontHeadlineOneFamily} !important;
        font-weight: ${theme.fontHeadlineOneWeight};
        text-transform: ${theme.fontHeadlineOneTransform};
    `};
`;

const heading3Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeThreeSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontHeadlineThreeLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeThreeMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontHeadlineTwoFamily} !important;
        font-weight: ${theme.fontHeadlineTwoWeight};
        text-transform: ${theme.fontHeadlineTwoTransform};
    `};
`;

const heading4Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeFourSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontHeadlineThreeLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeFourMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontHeadlineThreeFamily} !important;
        font-weight: ${theme.fontHeadlineThreeWeight};
        text-transform: ${theme.fontHeadlineThreeTransform};
    `};
`;

const heading5Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeFiveSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontSubHeadlineLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeFiveMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontSubHeadlineFamily} !important;
        font-weight: ${theme.fontSubHeadlineWeight};
        text-transform: ${theme.fontSubHeadlineTransform};
    `};
`;

const heading6Css = css`
    font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeSixSmall}`};
    letter-spacing: ${({ theme }) => `${theme.fontSubHeadlineLetterSpacing}`};
    @media (min-width: ${({ theme }) => `${theme.breakpointExtraLarge}px`}) {
        font-size: ${({ theme }) => `${theme.sizeTextHeadlineSizeSixMedium}`};
    }

    ${({ theme }) => css`
        font-family: ${theme.fontSubHeadlineFamily} !important;
        font-weight: ${theme.fontSubHeadlineWeight};
        text-transform: ${theme.fontSubHeadlineTransform};
    `};
`;

const IndentedLine = styled.span`
    text-indent: 14%;
    display: inline-block;
    width: 100%;
`;

const HighlightPart = styled.b<{ $highlightColor?: string }>`
    ${props => (props.$highlightColor ? `color: ${props.$highlightColor};` : '')}
    ${({ theme }) => css`
        font-family: ${theme.fontTitleHighlightFamily};
        font-weight: ${theme.fontTitleHighlightWeight};
        text-transform: ${theme.fontTitleHighlightTransform};
        letter-spacing: ${theme.fontTitleLetterSpacing}};
    `};
`;
